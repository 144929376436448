import React, { useState } from "react";
import { Link } from "gatsby";
import axios from "axios";
import Select from "react-select";
import Input from "./Input/Input";

// Styles
import StyledCard from "../StyledCardV2/StyledCardV2";
import styles from "./form.module.sass";

// Icons
import Plus from "../../../icons/plus.svg";
import Checkmark from "../../../icons/form/checkmark.svg";
import WhatsAppIcon from "../../../icons/form/whatsapp.svg";
import PhoneIcon from "../../../icons/form/phone.svg";
import TelegramIcon from "../../../icons/form/telegram.svg";
import SkypeIcon from "../../../icons/form/skype.svg";
import WeChatIcon from "../../../icons/form/wechat.svg";

// Data
const contactType = [
    {
        value: "whatsapp",
        label: "WhatsApp",
        icon: <WhatsAppIcon />,
    },
    {
        value: "phone",
        label: "Phone",
        icon: <PhoneIcon />,
    },
    {
        value: "telegram",
        label: "Telegram",
        icon: <TelegramIcon />,
    },
    {
        value: "skype",
        label: "Skype",
        icon: <SkypeIcon />,
    },
    {
        value: "wechat",
        label: "WeChat",
        icon: <WeChatIcon />,
    },
];

const errors = {
    name: true,
    email: true,
    contact: true,
    comment: true,
    check1: true,
    check2: true,
};

const Form = () => {
    const [file, setFile] = useState("Attach file");
    const [contactState, setContactState] = useState("whatsapp");

    const onFileAdd = (e) => {
        const text =
            e.target.files[0].name.length > 10
                ? `${e.target.files[0].name.slice(0, 10)}...`
                : e.target.files[0].name;
        setFile(`Attached: ${text}`);
    };

    const [success, setSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        formData.append("file", e.target[5].value);
        formData.append("contactType", contactState);
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
            form.reset();
            document
                .querySelectorAll('input[type="checkbox"]')
                .forEach((el) => {
                    el.checked = false;
                });
            for (let o in errors) errors[o] = true;
            setDisabled(allTrue(errors));
        }, 5000);
    };

    const customSingleValue = ({ data }) => (
        <div className={styles.singleValue + " input-select__single-value"}>
            {data.icon && (
                <span className={styles.singleValueIcon + "input-select__icon"}>
                    {data.icon}
                </span>
            )}
            <span>{data.label}</span>
        </div>
    );
    const CustomOption = ({ innerProps, isDisabled, data }) =>
        !isDisabled ? (
            <div
                {...innerProps}
                className={styles.withIcon + " react-select__option"}
            >
                {data.icon && <span className={styles.icon}>{data.icon}</span>}
                <span>{data.label}</span>
            </div>
        ) : null;

    const [disabled, setDisabled] = useState(true);
    const addErrors = (el, val) => {
        errors[el] = val;
        setDisabled(allTrue(errors));
    };

    const handleCheckChange = (e, check) => {
        errors[check] = !e.target.checked;
        setDisabled(allTrue(errors));
    };

    const allTrue = (obj) => {
        for (let o in obj) if (obj[o]) return true;
        return false;
    };

    return (
        <form
            action=""
            className={styles.form}
            onSubmit={(e) => handleSubmit(e)}
        >
            <Input
                type="half"
                id="home_contact_name"
                inputType="text"
                name="name"
                plcaeholder="Full name"
                setErrorM={(el, val) => addErrors(el, val)}
                required
            />
            <Input
                type="half"
                id="home_contact_subject"
                inputType="email"
                name="email"
                plcaeholder="Email"
                setErrorM={(el, val) => addErrors(el, val)}
                required
            />
            <div
                className={
                    "react-sel focused " +
                    styles.container +
                    " " +
                    styles.contact
                }
            >
                <Select
                    options={contactType}
                    classNamePrefix="react-select"
                    components={{
                        SingleValue: customSingleValue,
                        Option: CustomOption,
                    }}
                    isSearchable={false}
                    defaultValue={contactType[0]}
                    onChange={(val) => setContactState(val.value)}
                />
            </div>

            <Input
                id="home_contact_contact"
                inputType="text"
                type="auto"
                name="contact"
                contactType={contactState}
                setErrorM={(el, val) => addErrors(el, val)}
                plcaeholder="Contact"
                required
            />
            <Input
                id="home_contact_comment"
                inputType="textarea"
                name="comment"
                setErrorM={(el, val) => addErrors(el, val)}
                plcaeholder="Tell us about your project"
            />

            <div className={styles.attachText}>
                <div className={styles.attach}>
                    <label htmlFor="file">
                        <StyledCard type="reverse border-small">
                            <span className="button button-small">
                                <Plus />
                            </span>
                        </StyledCard>
                        <p>{file}</p>
                        <input
                            type="file"
                            id="file"
                            name="file"
                            onChange={(e) => onFileAdd(e)}
                        />
                    </label>
                </div>

                <p>
                    As a result of submitting completed “Contact Us” form, your
                    personal data will be processed by Madfish.Solutions and its
                    subsidiary entities worldwide. Please read our{" "}
                    <Link to="/privacy-policy">Privacy Policy</Link> for more
                    information. If you have any questions or would like to
                    withdraw your consent, please send your request to{" "}
                    <a href="mailto:info@madfish.solutions">
                        info@madfish.solutions
                    </a>
                    .
                </p>
            </div>

            <div className={styles.checkmarks}>
                <label htmlFor="checkbox" className={styles.label}>
                    <input
                        id="checkbox"
                        type="checkbox"
                        className={styles.input}
                        onChange={(e) => handleCheckChange(e, "check1")}
                    />
                    <span className={styles.checkmark}>
                        <Checkmark />
                    </span>
                    I am informed about processing of my personal data and the
                    right to withdraw my consent
                </label>

                <label htmlFor="checkbox2" className={styles.label}>
                    <input
                        id="checkbox2"
                        type="checkbox"
                        className={styles.input}
                        onChange={(e) => handleCheckChange(e, "check2")}
                    />
                    <span className={styles.checkmark}>
                        <Checkmark />
                    </span>
                    I agree to be included into Madfish Solutions' IT systems
                    for the purpose of being contacted
                </label>
            </div>

            <div
                className={
                    styles.buttons + " " + (success && styles.successButtons)
                }
            >
                <button
                    className="button button-primary"
                    type="submit"
                    disabled={disabled}
                >
                    <span className="content">Send message</span>
                </button>
                <div className={styles.success}>
                    Your message has been sent successfully
                    <Checkmark />
                </div>
            </div>
        </form>
    );
};

export default Form;
