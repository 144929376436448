import React, { useState } from "react"

import styles from "./input.module.sass"

const Input = ({ id, plcaeholder, name, inputType, type, contactType, setErrorM }) => {
  const inputClasses = [styles.label]

  const [state, setState] = useState({
    value: "",
    touched: false,
  })

  const validate = val => {
    if (name === "name") {
      switch (true) {
        case val.length === 0:
          return "Name is required"
        case val.length < 4:
          return "Name is too short. Min - 4 symbols"
        case val.length > 16:
          return "Name is too long. Min - 16 symbols"
      }
    }
    if (name === "email") {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      switch (true) {
        case val.length === 0:
          return "Email is required"
        case !re.test(val.toLowerCase()):
          return "Please type your email correctly"
      }
    }
    if (name === "comment") {
      switch (true) {
        case val.length === 0:
          return "Comment is required"
        case val.length < 15:
          return "Message is too short. Min - 15 symbols"
        case val.length > 500:
          return "Message is too short. Max - 500 symbols"
      }
    }
    if (contactType && contactType === "whatsapp") {
      switch (true) {
        case val.length === 0:
          return "Whatsapp login is required"
        case val.length < 3:
          return "Whatsapp login is too short. Min - 3 symbols"
        case val.length > 50:
          return "Whatsapp login is too short. Maz - 50 symbols"
      }
    }
    if (contactType && contactType === "phone") {
      const re = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/
      switch (true) {
        case val.length === 0:
          return "Phone number is required"
        case !re.test(val.toLowerCase()):
          return "Please type your phone number correctly"
      }
    }
    if (contactType && contactType === "skype") {
      const re = /[a-zA-Z][a-zA-Z0-9_\-\,\.]{5,31}/
      switch (true) {
        case val.length === 0:
          return "Skype login is required"
        case !re.test(val.toLowerCase()):
          return "Please type your skype login correctly"
      }
    }
    if (contactType && contactType === "telegram") {
      switch (true) {
        case val.length === 0:
          return "Telegram login is required"
        case val.length < 3:
          return "Telegram login is too short. Min - 3 symbols"
        case val.length > 50:
          return "Telegram login is too short. Maz - 50 symbols"
      }
    }
    if (contactType && contactType === "wechat") {
      switch (true) {
        case val.length === 0:
          return "WeChat login is required"
        case val.length < 3:
          return "WeChat login is too short. Min - 3 symbols"
        case val.length > 50:
          return "WeChat login is too short. Maz - 50 symbols"
      }
    }
  }

  const errors = validate(state.value)


  const handleBlur = () => {
    setState({ ...state, touched: true })
    setErrorM(name, validate(state.value) ? true : false)
  }
  const handleChange = e => {
    setState({ ...state, value: e.target.value })
    setErrorM(name, validate(e.target.value) ? true : false)
  }


  const shouldMarkError = () => {
    const hasError = errors
    const shouldShow = state.touched

    return hasError ? shouldShow : false
  }

  const err = shouldMarkError()

  if (err) inputClasses.push(styles.errorLabel)
  // else if (state.touched) inputClasses.push(styles.successLabel)

  return (
    <label
      htmlFor={id}
      className={
        inputClasses.join(" ") +
        ` ` +
        (type === "half" ? styles.half : null) +
        ` ` +
        (type === "auto" ? styles.auto : null)
      }
    >
      <span className={styles.border} />
      <span className={styles.borderHover} />
      {inputType === "textarea" ? (
        <textarea
          type={inputType}
          placeholder={plcaeholder}
          name={name}
          id={id}
          className={styles.textarea}
          // required={required}
          onChange={e => handleChange(e)}
          onBlur={() => handleBlur()}
        ></textarea>
      ) : (
        <input
          type={inputType}
          placeholder={plcaeholder}
          name={name}
          id={id}
          className={styles.input}
          // required={required}
          onChange={e => handleChange(e)}
          onBlur={() => handleBlur()}
        />
      )}
      <span className={styles.error}>{err && errors}</span>
    </label>
  )
}

export default Input
