import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// Components
import Form from "../../Global/Form/Form"

// Styles
import styles from "./contacts.module.sass"

const ContactsScreen = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "team/Vlad_Horilyi.jpeg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="section" id="contactsScreen">
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className={styles.wrapper}>
            <div className={styles.call}>
              <div className={styles.imageWrapper}>
                <div className={styles.imageInner}>
                  <div className={styles.image}>
                    <Img
                      fluid={data.image.childImageSharp.fluid}
                      alt={data.image.base.split(".")[0]} // only use section of the file extension with the filename
                    />
                  </div>
                </div>
              </div>
              <h4>Vlad Horilyi</h4>
              <p>Business Developer</p>
              {/*<p>Let's make a quick call to discuss your project</p>*/}
              <p>Our business developer will answer all your questions. Fill the form!</p>
              {/*<a*/}
              {/*  href="https://calendly.com/madfish-solutions/15min"*/}
              {/*  target="_blank"*/}
              {/*  rel="noopener noreferrer"*/}
              {/*  className="button button-primary"*/}
              {/*>*/}
              {/*  <span className="border"></span>*/}
              {/*  <span className="content">Book a call</span>*/}
              {/*</a>*/}
            </div>
            <div className={styles.form}>
              <h2>Contact us</h2>
              <Form />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactsScreen
