import React from "react"
import { Link } from "gatsby"

// Styles
import styles from "./styled-card.module.sass"

// Icons
import GithubIcon from "../../../icons/github.svg"

const StyledCard = ({
  children,
  // isOpenSource = false,
  hovered = false,
  type,
  external = false,
  link = false,
  linkOpen = false,
  flexContent = false,
  zindex = false,
  mouseEnter = () => {},
  mouseLeave = () => {},
}) => {
  const cardStyles = [styles.card]
  if (type) {
    if (type.includes("hideDefault")) {
      cardStyles.push(styles.hideDefault)
    }
    if (type.includes("reverse")) {
      cardStyles.push(styles.reverse)
    }
    if (type.includes("green")) {
      cardStyles.push(styles.green)
    }
    if (type.includes("zindex")) {
      cardStyles.push(styles.zindex)
    }
  }
  if (link) {
    cardStyles.push(styles.linked)
  }
  if (hovered) {
    cardStyles.push(styles.hovered)
  }
  if (flexContent) {
    cardStyles.push(styles.flexContent)
  }

  return (
    <div
      className={cardStyles.join(" ")}
      onMouseEnter={() => mouseEnter()}
      onMouseLeave={() => mouseLeave()}
    >
      {link !== false ? (
        external ? (
          <a
            href={link}
            target="_blank"
            className={styles.link}
            rel="noopener noreferrer"
          >
            {link}
          </a>
        ) : (
          <Link to="/index-2" className={styles.link}>
            {link}
          </Link>
        )
      ) : null}
      {linkOpen ? (
        <a
          href={linkOpen}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.openWrapper}
        >
          <div className={styles.open}>
            <div className={styles.openContent}>
              <GithubIcon />
              github
            </div>
          </div>
        </a>
      ) : null}
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default StyledCard
